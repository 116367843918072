import { axiosConfig } from '../api/axios-config.js'

// 錯誤回報
export const deviceReport = {
  // 取得清單
  get: (id, token) => axiosConfig.get(`/api/device-report/${id || ''}`, token),

  // 更新
  update: (id, data, token) => axiosConfig.patch(`/api/device-report/${id}`, data, token)
}
