<template>
  <div class="device-report">
    <div class="container-fluid py-4">
      <h2 class="h4 fw-medium mb-4">{{ $t("__errorReport") }}</h2>
      <div class="d-flex align-items-center flex-wrap mb-3">
        <div class="device-report-select">
          <select v-model="searchType" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t("__all") }}</option>
            <option value="1">
              {{ $t("__processed") }}
            </option>
            <option value="0">
              {{ $t("__pending") }}
            </option>
          </select>
        </div>
        <div class="ms-2 device-report-select">
          <select v-model="sortDeviceReport" class="form-select rounded-pill me-2">
            <option :value="null">{{ $t('__sort') }}</option>
            <option :value="['updated_at', 'asc']">{{ $t('__sortUpdateOldToNew') }}</option>
            <option :value="['updated_at', 'desc']">{{ $t('__sortUpdateNewToOld') }}</option>
          </select>
        </div>
        <div class="ms-md-2 mt-2 mt-md-0">
          <div class="input-group rounded-pill">
            <input
              v-model="searchSerialId"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchDeviceSerialId')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="button"
              class="btn btn-link px-2 text-dark"
              id="search"
              @click="getDeviceReport(1)"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter.serial_id"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t("__clearSearch") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body" v-if="items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            :fields="fields"
            :empty-text="$t('__notData')"
            :items="items.data"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(agency)="data">
              {{
                data.item.device.agency 
                  ?  data.item.device.agency.name + " / " + data.item.device.agency.codename
                  : ''
              }}
            </template>
            <template v-slot:cell(manager)="data">
              <div v-if="isSystemManage && data.item.device.manager_id !== userInfo.id">
              {{
                data.item.device.manager
                  ? data.item.device.manager.name + " / " + data.item.device.manager.username
                  : ''
              }}
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <span :class="data.item.status ? 'text-primary' : 'text-danger'">
                {{
                  data.item.status ? $t("__processed") : $t("__pending")
                }}</span
              >
              {{ " / " + getTypeName(data.item.type) }}
            </template>
            <template v-slot:cell(created_at)="data">
              {{ new Date(data.item.created_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(updated_at)="data">
              {{ new Date(data.item.updated_at).format("yyyy-MM-dd hh:mm") }}
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <button
                  @click="showModal(data.item.id)"
                  type="button"
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__edit')"
                >
                  <i class="bi bi-pencil mx-1 fs-5"></i>
                </button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row align-items-center mt-3" v-if="items">
        <div class="col-sm col-12 text-right text-sm-left">
          {{ $t('__paginationInfo', {
            startNub: items.current_page == 1
              ? 1
              : items.per_page * (items.current_page - 1) + 1,
            entNub: currentPage == 1
              ? items.total > items.per_page
                ? items.per_page
                : items.total
              : items.per_page * (items.current_page - 1) + items.per_page >
                items.total
              ? items.total
              : items.per_page * (items.current_page - 1) + items.per_page ,
            sum: items.total
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="items.total > items.per_page"
            v-model="items.current_page"
            :total-rows="items.total"
            :per-page="items.per_page"
            align="right"
            first-number
            last-number
            @click.native="pagination(items.current_page)"
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="modal fade cms-style" tabindex="-1" id="edit" ref="editModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium">
                {{
                  $t("__serialNumber") +
                  "：" +
                  modalData.id +
                  " " +
                  $t("__processingReturns")
                }}
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t("__errorType")
                }}</label>
                <p class="text-secondary">{{ getTypeName(modalData.type) }}</p>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t("__troubleTime")
                }}</label>
                <p class="text-secondary">
                  {{
                    new Date(modalData.trouble_time).format("yyyy-MM-dd hh:mm")
                  }}
                </p>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t("__description")
                }}</label>
                <p class="text-secondary">{{ modalData.description }}</p>
              </div>
              <div class="mb-3">
                <label for="status" class="form-label fw-medium required">{{
                  $t("__processingStatus")
                }}</label>
                <select
                  v-model="modalData.status"
                  id="status"
                  class="form-select"
                  required
                >
                  <option :value="1">
                    {{ $t("__processed") }}
                  </option>
                  <option :value="0">
                    {{ $t("__pending") }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label for="note" class="form-label fw-medium required">{{
                  $t("__processingContent")
                }}</label>
                <textarea
                  v-model="modalData.note"
                  class="form-control"
                  id="note"
                  rows="3"
                  required
                ></textarea>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-outline-secondary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t("__cancel") }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t("__save") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tooltip, Modal } from 'bootstrap'
import i18n from '@/lang/lang.js'
import { deviceReport } from '@/http/api/errorReport.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DeviceReport',
  data () {
    return {
      items: null,
      searchType: null,
      searchSerialId: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      bootstrap_tooltip: null,
      bootstrap_modal: null,
      isEdit: false,
      editModalId: null,
      filter: {
        serial_id: null,
        status: null,
        sort_by: null,
        sort_type: null
      },
      modalData: {
        id: null,
        type: null,
        trouble_time: null,
        description: null,
        status: null,
        note: null
      },
      sortDeviceReport: null
    }
  },
  computed: {
    ...mapState(['token', 'userInfo']),
    fields () {
      return [
        {
          key: 'id',
          label: i18n.t('__serialNumber'),
          class: 'align-middle'
        },
        {
          key: 'device.serial_id',
          label: i18n.t('__deviceId'),
          class: 'align-middle'
        },
        {
          key: 'agency',
          label: i18n.t('__agency') + ' / ' + i18n.t('__codeName'),
          class: 'align-middle'
        },
        {
          key: 'manager',
          label: i18n.t('__agent') + ' / ' + i18n.t('__account'),
          class: 'align-middle text-center'
        },
        {
          key: 'status',
          label: i18n.t('__status') + ' / ' + i18n.t('__errorType'),
          class: 'align-middle text-center'
        },
        {
          key: 'created_at',
          label: i18n.t('__notificationTime'),
          class: 'align-middle text-center'
        },
        {
          key: 'updated_at',
          label: i18n.t('__updated'),
          class: 'align-middle text-center'
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ]
    },
    permissions () {
      return this.userInfo.permissions
    },
    isSystemManage () {
      let systemManage = false
      this.permissions.forEach((item) => {
        if (item.name === 'system-manage') {
          systemManage = true
        }
      })
      return systemManage
    }
  },
  watch: {
    sortDeviceReport () {
      const vm = this
      vm.filter.sort_by = vm.sortDeviceReport ? vm.sortDeviceReport[0] : null
      vm.filter.sort_type = vm.sortDeviceReport ? vm.sortDeviceReport[1] : null
      vm.getDeviceReport(1)
    },
    searchType () {
      const vm = this
      vm.getDeviceReport(1)
    },
    items (data) {
      const vm = this
      if (data) {
        vm.initBootstrapTooltip()
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    getTypeName (id) {
      switch (id) {
        case 1:
          return i18n.t('__breathe')
        case 2:
          return i18n.t('__getup')
        case 3:
          return i18n.t('__longInBed')
        case 4:
          return i18n.t('__leaveTheBed')
        case 5:
          return i18n.t('__restless')
      }
    },
    getDeviceReport (page) {
      const vm = this
      var config = Object.assign({}, this.token)
      vm.filter.serial_id = vm.searchSerialId
      vm.filter.status = vm.searchType
      config.params = this.filter
      config.params.page = page
      vm.Loading()
      deviceReport
        .get('', config)
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.items = res.data.data
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
          vm.Loaded()
        })
        .catch((err) => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
          vm.Loaded()
        })
    },
    clearSearch () {
      this.searchSerialId = null
      this.getDeviceReport(1)
    },
    pagination (currentPage) {
      this.getDeviceReport(currentPage)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    initBootstrapTooltip () {
      this.$nextTick(function () {
        this.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
          })
        document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(ele => {
          ele.addEventListener('shown.bs.tooltip', function () {
            setTimeout(() => {
              Tooltip.getInstance(ele).hide()
            }, 600)
          })
        })
      })
    },
    hideTooltip () {
      if (this.bootstrap_tooltip && this.bootstrap_tooltip.length) {
        this.bootstrap_tooltip.forEach((tooltip) => {
          tooltip.hide()
        })
      }
    },
    showModal (id) {
      const vm = this
      vm.editModalId = id
      if (id) {
        vm.isEdit = true
        vm.items.data.forEach((item) => {
          if (item.id === id) {
            vm.modalData.id = id
            vm.modalData.status = item.status
            vm.modalData.note = item.note
            vm.modalData.type = item.type
            vm.modalData.trouble_time = item.trouble_time
            vm.modalData.description = item.description
          }
        })
      } else {
        vm.isEdit = false
        vm.modalData.id = null
        vm.modalData.status = null
        vm.modalData.note = null
        vm.modalData.type = null
        vm.modalData.trouble_time = null
        vm.modalData.description = null
      }
      vm.bootstrap_modal = new Modal(vm.$refs.editModal)
      vm.$nextTick(function () {
        vm.bootstrap_modal.show()
      })
    },
    submitForm () {
      const vm = this
      vm.Loading()
      deviceReport
        .update(
          vm.editModalId,
          {
            status: vm.modalData.status,
            note: vm.modalData.note
          },
          vm.token
        )
        .then((res) => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDeviceReport(1)
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
          }
          vm.Loaded()
          vm.Alerted()
        })
    }
  },
  created () {
    this.getDeviceReport(1)
  },
  mounted () {}
}
</script>

<style lang="scss">
</style>
